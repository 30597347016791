import logo from '../res/Logo.svg'
import menuIcon from '../res/burger-menu.png'
import {useHistory} from "react-router-dom"
import {useEffect, useState} from "react";

export function MenuBar(props) {

    const history = useHistory();

    //Burgermenu einbauen
    const [smallWindow, setSmallWindow] = useState(window.matchMedia('(max-width: 780px)').matches);
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);


    useEffect(() => {
        window.matchMedia('(max-width: 780px)').addEventListener('change', e => {setSmallWindow(e.matches)})
    }, []);

    if(burgerMenuOpen){
        return (
            <div>
                <div className="flex w-full justify-between">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='justify-center'
                                                                                                     alt="logo" style={{
                        height: 40,
                        width: 50
                    }}></img></a>
                    <button onClick={e => {
                        setBurgerMenuOpen(!burgerMenuOpen);
                    }}><img src={menuIcon} style={{height: 40, width: 40}}/></button>
                </div>
                <div className='absolute right-0 w-1/2 h-full bg-stdBg/50 z-50' onClick={e => {
                    setBurgerMenuOpen(false)
                }}>

                    <div className="w-full mt-4 grid gap-4 grid-cols-1 z-50">
                        <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                                onClick={() => {
                                    history.push("/")
                                }}>Home
                        </button>
                        <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                                onClick={() => {
                                    history.push("/about")
                                }}>About
                        </button>
                        <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                                onClick={() => {
                                    history.push("/projects")
                                }}>Projects
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    //display Burger menu icon when window is small
    if (smallWindow) {
        return (
            <div className="flex w-full justify-between">
                <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                 className='justify-center'
                                                                                                 alt="logo" style={{
                    height: 40,
                    width: 50
                }}></img></a>
                <button onClick={e => {
                    console.log("clicked");
                    setBurgerMenuOpen(true);
                }}><img src={menuIcon} style={{height: 40, width: 40}}/></button>
            </div>
        )
    }


    //Desktop size menu
    if (history.location.pathname === "/blog") {
        return (
            <div className="sticky top-0 shadow-md !w-full rounded-lg overflow-hidden z-30 bg-stdBg">
                <div className="w-full grid md:grid-cols-4 gap-4 sm:grid-cols-1">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='justify-center'
                                                                                                     alt="logo" style={{
                        height: 60,
                        width: 100
                    }}></img></a>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/")
                            }}>Home
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/about")
                            }}>About
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/projects")
                            }}>Projects
                    </button>
                </div>
                <hr/>
            </div>
        );
    } else if (window.location.pathname === "/about") {
        return (
            <div className="sticky top-0 shadow-md !w-full rounded-lg overflow-hidden z-30 bg-stdBg">
                <div className="w-full grid md:grid-cols-4 gap-4 sm:grid-cols-1">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='logo justify-center'
                                                                                                     alt="logo" style={{
                        height: 60,
                        width: 100
                    }}></img></a>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/")
                            }}>Home
                    </button>
                    <button className="active transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/about")
                            }}>About
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/projects")
                            }}>Projects
                    </button>
                </div>
                <hr/>
            </div>
        );
    } else if (window.location.pathname === "/projects") {
        return (
            <div className="sticky top-0 shadow-md !w-full rounded-lg overflow-hidden z-30 bg-stdBg">
                <div className="w-full grid md:grid-cols-4 gap-4 sm:grid-cols-1">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='logo justify-center'
                                                                                                     alt="logo" style={{
                        height: 60,
                        width: 100
                    }}></img></a>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/")
                            }}>Home
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/about")
                            }}>About
                    </button>
                    <button className="active transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/projects")
                            }}>Projects
                    </button>
                </div>
                <hr/>
            </div>
        );
    } else if (window.location.pathname === "/") {
        return (
            <div className="sticky top-0 shadow-md !w-full rounded-lg overflow-hidden z-30 bg-stdBg">
                <div className="w-full grid md:grid-cols-4 gap-4 sm:grid-cols-1">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='logo justify-center'
                                                                                                     alt="logo" style={{
                        height: 60,
                        width: 100
                    }}></img></a>
                    <button className="active transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/")
                            }}>Home
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/about")
                            }}>About
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/projects")
                            }}>Projects
                    </button>
                </div>
                <hr/>
            </div>
        );
    } else {
        return (
            <div className="sticky top-0 shadow-md !w-full rounded-lg overflow-hidden z-30 bg-stdBg">
                <div className="w-full grid md:grid-cols-4 gap-4 sm:grid-cols-1">
                    <a className='transition transform-gpu delay-75 hover:-scale-y-95' href='/'><img src={logo}
                                                                                                     className='logo justify-center'
                                                                                                     alt="logo" style={{
                        height: 60,
                        width: 100
                    }}></img></a>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/");
                                window.location.reload(false)
                            }}>Home
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/about");
                                window.location.reload(false)
                            }}>About
                    </button>
                    <button className="transition transform-gpu delay-75 ease-in-out hover:-translate-y-1"
                            onClick={() => {
                                history.push("/projects");
                                window.location.reload(false)
                            }}>Projects
                    </button>
                </div>
                <hr/>
            </div>
        );
    }


}