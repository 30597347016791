import {IndividualProject, LoadProject} from "../Items/IndividualProject"
import React, { useState } from 'react'

import backArrow from '../res/backArrow.png'

let title = "Test"

export function ProjectsPage(selectionViewActive = true){
    const [reload, setReload] = useState(true);

    if(reload === true){
        return (
            <div className="flex justify-center py-5 font-serif">
                <div className="md:py-10 md:px-20 sm:px-4 sm:py-4 md:columns-4 sm:columns-2">
                    <div onClick={() => {setReload(false); title = "Homemaster"}}><LoadProject descr="HomeMaster" imageName="Homemaster-Frontend.png" /></div>
                    <div onClick={() => {setReload(false); title = "LoggiFin"}}><LoadProject descr="LoggiFin" imageName="LoggiFin-Diagramm-Sample.png" /></div>
                    <div onClick={() => {setReload(false); title = "Studienprojekte"}}><LoadProject descr="Studienprojekte" /></div>
                    <div onClick={() => {setReload(false); title = "Kleine Spiele"}}><LoadProject descr="Kleine Spiele (in Java)" imageName="Neon Adventurer.png" /></div>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <a className="flex" href="" onClick={() => setReload(true)}>
                    <img className="w-6" src={backArrow}/>
                    <p className="content-center text-lime-200 font-serif">Go Back</p>
                </a>

                <IndividualProject imageName={title} title={title}/>
            </div>
        )
    }
}