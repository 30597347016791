// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 767px) {
    .graph {
        width: '90';
        height: '90';
    }
}

@media only screen and (min-width: 768px) {
    .graph {
        width: '10';
        height: '10';
    }
}`, "",{"version":3,"sources":["webpack://./src/res/aboutStyle.css"],"names":[],"mappings":"AAAA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":["@media only screen and (max-width: 767px) {\n    .graph {\n        width: '90';\n        height: '90';\n    }\n}\n\n@media only screen and (min-width: 768px) {\n    .graph {\n        width: '10';\n        height: '10';\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
