import React from 'react'
import { FaInstagram, FaLinkedin } from "react-icons/fa";

export function Footer() {
    return (
        <div className="sticky rounded-xl m-auto !bottom-0 w-fit bg-stdBg p-2">
            <div className="grid grid-cols-2">
                <a href='https://www.instagram.com/marvphoto2019/' rel='noreferrer' target='_blank'><FaInstagram
                    size={40}/></a>
                <a href='https://de.linkedin.com/in/marian-voigtl%C3%A4nder-ba0a3a2b0' rel='noreferrer' target='_blank'><FaLinkedin
                    size={40}/></a>
            </div>
            <div className="flex items-center justify-center text-white">
                <a href="/privacy">Datenschutz</a>
            </div>
        </div>
    );
}