import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as GlobalProperties from "../util/GlobalProperties";

async function loginUser(credentials) {
    return fetch(GlobalProperties.apiUrl + 'login?username=' + credentials.username + '&password=' + credentials.password, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(data => data.json())
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });

        setToken(token);
    }

    return(
        <div className="login-wrapper font-serif flex place-content-center m-10">
            <form>
                <h1 className="text-3xl my-3">Login!</h1>
                <label>
                    <p>Username</p>
                    <input type="text" className="text-black font-serif" onChange={e => setUserName(e.target.value)}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" className="text-black font-serif" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                    <button className="my-4 bg-blue-400 rounded px-2" type="submit" onClick={e => handleSubmit(e)}>Submit</button>
                </div>
            </form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};