import {ImageWithPlaceholder} from "../Items/ImageWithPlaceholder";

export function DisplayHome() {

    let born = new Date("January 11, 2003")
    let now = Date.now()

    return (
        <div className="font-serif bg-startPage bg-contain bg-no-repeat bg-cover">
            <div className='flex place-content-center z-30'>
                <h1 className="text-3xl justify-center container m-8 font-serif">Willkommen auf mphion.de</h1>
                <h2 className="text-xs italic">Diese Website ist noch in der Entwicklung. Um Fehler zu melden können gerne die unten gelisteten Links zu LinkedIn und Instagram verwendet werden.</h2>
            </div>
            <div className='flex place-content-center z-30'>
                <ImageWithPlaceholder hasImagePlaceholder={true} folderUrl='res/' fileName='me.jpg' styleClassesImage='rounded-full' styleClassesContainer='flex place-content-center md:w-1/6 !w-1/4' />
            </div>

            <div className="rounded m-auto sm:m-6 md:mx-10 bg-slate-100 text-gray-900 text-lg shadow-md z-30">
                <p>Hi! Ich bin Marian, {Math.floor((now-born)/1000/60/60/24/365)} Jahre alter Informatiker aus dem wunderschönen Harzvorland.
                Hier Kurz ein paar Informationen zu mir. In meiner Freizeit unternehme ich gerne etwas mit Freunden oder Familie, spiele aber auch Videospiele diverser Art, programmiere natürlich auch (siehe <a href='Projects' className='text-blue-400'>meine Projekt :D</a>) und mache Fotos mit meiner Spiegelreflexkamera.
                Davon sind einige bei <a href='https://www.instagram.com/marvphoto2019/' rel='noreferrer' target='_blank' className='text-blue-400'>Instagram</a> zu finden. Aber erstmal genug zu mir. Freut mich, dass du hier gelandet bist! Viel Spaß beim anschauen meiner Website!
                </p>
            </div>
            
        </div>
    )
}