import React, {useEffect} from "react";
import {getProjectsText} from "../util/ApiConnector";
import DOMPurify from 'dompurify'
import '../res/projectsStyle.css'
import {ImageWithPlaceholder} from "./ImageWithPlaceholder";

export function LoadProject(props){
    
    return (
        <div className="bg-white mt-2 box-border rounded-md h-64 w-64 shadow-md transition transform-gpu ease-in-out hover:scale-110 text-gray-900" >
            <h1 className="text-center text-2xl hover:z-50">{props.descr}</h1>
            <br />
            {(props.imageName === "" || props.imageName === null || props.imageName === undefined) ?
            <img alt={props.imageName} /> : <ImageWithPlaceholder hasImagePlaceholder={false} folderUrl='res/ProjectPictures/' fileName={props.imageName} /> }

            
        </div>
    )
}

export function IndividualProject(props) {

    const [text, setText] = React.useState("");

    useEffect( () => {
        async function getProjectsTextAsync(){
            setText(await getProjectsText(props.title))
        }

        getProjectsTextAsync();
        }, [setText]);

    try{
    return (
        <div className="">
            <h1 className="">{props.title}</h1>

            <div className="content m-3" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}}></div>

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-10 p-10 items-center shadow-md -z-10">
                <ImageWithPlaceholder hasImagePlaceholder={false} folderUrl={`res/${props.imageName}/`} fileName={props.imageName + "1.png"} styleClassImage="resizingImage" />
                <ImageWithPlaceholder hasImagePlaceholder={false} folderUrl={`res/${props.imageName}/`} fileName={props.imageName + "2.png"} styleClassImage="resizingImage" />
                <ImageWithPlaceholder hasImagePlaceholder={false} folderUrl={`res/${props.imageName}/`} fileName={props.imageName + "3.png"} styleClassImage="resizingImage" />
                <ImageWithPlaceholder hasImagePlaceholder={false} folderUrl={`res/${props.imageName}/`} fileName={props.imageName + "4.png"} styleClassImage="resizingImage" />
            </div>
        </div>)} catch (e){
        return (
            <div className="">
                <h1 className="">{props.title}</h1>

                <div className="content m-3" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}}></div>
            </div>
        )
    }
}