export function CvEntry(cvData: string) {
    let dataArray = cvData.cvData.split(";");

    if (dataArray.length === 0 || dataArray[0] === "") {
        return (
            <div>
                <li>Sehr gute Kenntnisse in gängigen IDEs wie Visual Studio 2022, PyCharm, IntelliJ und CLion</li>
                <li>Sehr gute Kenntnisse in Java und gängigen Webframeworks</li>
                <li>Gute Kenntnisse in Javascript und React</li>
                <li>Gute Kenntnisse in Python</li>
                <li>Gute Kenntnisse in C, C++ und C#</li>
                <li>Erfahrung in Embedded Entwicklung durch private Projekte mit Arduino und Raspberry Pi</li>
                <li>Grundlegende Erfahrung mit Netzwerkinfrastuktur und Hosting durch eigenes Homelab</li>
                <li>Gute Kenntnisse im Themengebiet Data Science und Informationsvisualisierung</li>
            </div>
        );
    }

    return (
        <div>
            <ul className='list-disc list-inside py-5'>
                {dataArray.map(item => <li key={item}>{item}</li>)}
            </ul>
        </div>
    )
}