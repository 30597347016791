import React, {useState} from 'react'
import ReactFlow, {ReactFlowProvider} from 'reactflow'

import 'reactflow/dist/style.css';
import {getCvDataFromApi} from "../util/ApiConnector";
import { CvEntry } from "../Items/CvEntry";

const schoolNodes = [
    { id: '1', position: { x: 300, y: 50 }, data: { label: '2009 - 2013 Grundschule' } },
    { id: '2', position: { x: 300, y: 150 }, data: { label: '2013 - 2021 Gymnasium' } },
    { id: '3', position: { x: 300, y: 250 }, data: { label: '2021 Hochschulreife' } },
    { id: '4', position: { x: 300, y: 350 }, data: { label: 'Mitte 2021 - 2025 Duales Studium Informatik an der Hochschule Ostfalia für Angewandte Wissenschaften' } },
  ];
const schoolEdges = [{ id: 'e1-2', source: '1', target: '2' }, 
                        { id: 'e2-3', source: '2', target: '3' },
                        { id: 'e3-4', source: '3', target: '4' }];

const practicalExperience = [
    { id: '11', position: { x: 500, y: 50 }, data: { label: 'seit 2010 Entwicklung eigener Programme als Hobby' } },
    { id: '31', position: { x: 500, y: 150 }, data: { label: '2018 Praktikum Softwareentwickler bei NetCo Professional Services GmbH' } },
    { id: '21', position: { x: 500, y: 250 }, data: { label: '2018 - 2021 Teilnahme an Wettkämpfen mit Lego Mindstorms EV3 Robotern, Projekte mit dem Raspberry Pi' } },
    { id: '51', position: { x: 700, y: 250 }, data: { label: 'seit 2018 Aufbau eines `Homelab` und self hosting verschiedener Services und selbst entwickelter Apps (siehe Projekte)' } },
    { id: '41', position: { x: 500, y: 400 }, data: { label: 'seit 2021 Duales Studium Informatik mit Praxispartner Volkswagen PKW' }},
];

const practicalExperienceEdges = [{ id: 'e11-21', source: '11', target: '21' }, 
                        { id: 'e21-31', source: '21', target: '31' },
                        { id: 'e51-31', source: '31', target: '51' },
                        { id: 'e31-41', source: '31', target: '41' }];

const proOptions = { hideAttribution: true };

let data: string = "";

export function AboutPage(){

    const [reload, setReload] = useState(false);

    async function HandleMouseOverEvent(reactEvent, node){
        if(reload === false){
            data = await getCvDataFromApi(node.data.label)
            setReload(true)
        }
    }

    var res = schoolNodes.concat(practicalExperience);
    var resEdges=  schoolEdges.concat(practicalExperienceEdges)


    return (
        <div className='grid h-full md:grid-cols-2 grid-flow-col font-serif font-light'>

            <div style={{width:'100%', alignItems: 'center'}}>
                <ReactFlowProvider>
                    <ReactFlow nodes={res} edges={resEdges} nodesConnectable={false} edgesUpdatable={false} proOptions={proOptions} onNodeMouseLeave={() => setReload(false)} onNodeClick={(ev, no) => HandleMouseOverEvent(ev, no)} />
                </ReactFlowProvider>
            </div>
            <div className='py-5 my-5 bg-slate-50 w-full rounded-3xl text-black'>
                
                <h1 className='text-xl'>Kenntnisse und Fähigkeiten</h1>
                <h2 className='text-lg'>Für mehr Informationen auf einzelne Nodes clicken!</h2>
                <br />
                
                <CvEntry cvData={data} />

            </div>

        </div>
    )
}