import {apiUrl} from "./GlobalProperties";

export async function getCvDataFromApi(label: string): Promise<string>{

    const resp = await fetch(apiUrl + "cventry?label=" + label, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    }).then(function (response){
        return response.text();
    })

    return resp
}

export async function getProjectsText(title: string) : Promise<string>{

    const resp = await fetch(apiUrl + "projectText?title=" + title, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    }).then(function (response){
        return response.text();
    })

    return resp;

}