export function DisplayPolicy(){
    return (
        <div className="privacy-policy">

                <h1>Datenschutzerklärung</h1>
                <p>Stand: 20. August 2024</p><h2>Inhaltsübersicht</h2>
                <ul className="index">
                        <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                        <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                        <li><a className="index-link" href="#m2427">Maßgebliche Rechtsgrundlagen</a></li>
                        <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                        <li><a className="index-link" href="#m25">Übermittlung von personenbezogenen Daten</a></li>
                        <li><a className="index-link" href="#m24">Internationale Datentransfers</a></li>
                        <li><a className="index-link" href="#m12">Allgemeine Informationen zur Datenspeicherung und
                                Löschung</a></li>
                        <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
                        <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebots und Webhosting</a>
                        </li>
                        <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
                        <li><a className="index-link" href="#m104">Blogs und Publikationsmedien</a></li>
                        <li><a className="index-link" href="#m182">Kontakt- und Anfrageverwaltung</a></li>
                </ul>
                <h2>Verantwortlicher</h2>
                <p>E-Mail-Adresse: marvphoto@web.de<a href="mailto:marvphoto@web.de">marvphoto@web.de</a>
                </p>

                <h2 id="mOverview">Übersicht der Verarbeitungen</h2><p>Die nachfolgende Übersicht fasst die Arten der
                verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
                Personen.</p><h3>Arten der verarbeiteten Daten</h3>
                <ul>
                        <li>Bestandsdaten.</li>
                        <li>Kontaktdaten.</li>
                        <li>Inhaltsdaten.</li>
                        <li>Nutzungsdaten.</li>
                        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                        <li>Protokolldaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                        <li>Kommunikationspartner.</li>
                        <li>Nutzer.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                        <li>Kommunikation.</li>
                        <li>Sicherheitsmaßnahmen.</li>
                        <li>Organisations- und Verwaltungsverfahren.</li>
                        <li>Feedback.</li>
                        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                        <li>Informationstechnische Infrastruktur.</li>
                </ul>
                <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2><p><strong>Maßgebliche Rechtsgrundlagen nach der
                DSGVO: </strong>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis
                wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der
                DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten
                ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
                Datenschutzerklärung mit.</p>
                <ul>
                        <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat
                                ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für
                                einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
                        </li>
                        <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - die
                                Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
                                Dritten notwendig, vorausgesetzt, dass die Interessen, Grundrechte und Grundfreiheiten
                                der betroffenen Person, die den Schutz personenbezogener Daten verlangen, nicht
                                überwiegen.
                        </li>
                </ul>
                <p><strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich zu den
                        Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland.
                        Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
                        Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                        Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
                        Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke
                        und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich
                        Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
                        gelangen.</p>
                <p><strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese Datenschutzhinweise dienen sowohl
                        der Informationserteilung nach dem Schweizer DSG als auch nach der Datenschutzgrundverordnung
                        (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen
                        Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der
                        im Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten", "überwiegendes
                        Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO verwendeten
                        Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse" und
                        "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch
                        im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.</p>

                <h2 id="m27">Sicherheitsmaßnahmen</h2><p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der
                Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und
                des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
                organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
                <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                        von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des
                        sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
                        ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
                        Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
                        gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
                        Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>

                <h2 id="m25">Übermittlung von personenbezogenen Daten</h2><p>Im Rahmen unserer Verarbeitung von
                personenbezogenen Daten kommt es vor, dass diese an andere Stellen, Unternehmen, rechtlich
                selbstständige Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen gegenüber
                offengelegt werden. Zu den Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte
                Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in eine Website eingebunden sind. In
                solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge
                bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>

                <h2 id="m24">Internationale Datentransfers</h2><p>Datenverarbeitung in Drittländern: Sofern wir Daten in
                einem Drittland (d. h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
                verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der
                Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet,
                erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Sofern das Datenschutzniveau in dem
                Drittland mittels eines Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser als
                Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur dann, wenn das Datenschutzniveau
                anderweitig gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
                ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich erforderlicher Übermittlung (Art.
                49 Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen
                Anbietern aus dem Drittland mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten.
                Informationen zu Drittlandtransfers und vorliegenden Angemessenheitsbeschlüssen können dem
                Informationsangebot der EU-Kommission entnommen werden: <a
                    href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
                    target="_blank" rel="noreferrer">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.</a>
        </p>
                <p>EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data Privacy Framework" (DPF)
                        hat die EU-Kommission das Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
                        Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste der
                        zertifizierten Unternehmen als auch weitere Informationen zu dem DPF können Sie der Website des
                        Handelsministeriums der USA unter <a href="https://www.dataprivacyframework.gov/"
                                                             target="_blank" rel="noreferrer">https://www.dataprivacyframework.gov/</a> (in
                        Englisch) entnehmen. Wir informieren Sie im Rahmen der Datenschutzhinweise, welche von uns
                        eingesetzten Diensteanbieter unter dem Data Privacy Framework zertifiziert sind.</p>

                <h2 id="m12">Allgemeine Informationen zur Datenspeicherung und Löschung</h2><p>Wir löschen
                personenbezogene Daten, die wir verarbeiten, gemäß den gesetzlichen Bestimmungen, sobald die
                zugrundeliegenden Einwilligungen widerrufen werden oder keine weiteren rechtlichen Grundlagen für die
                Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche Verarbeitungszweck entfällt oder
                die Daten nicht mehr benötigt werden. Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten
                oder besondere Interessen eine längere Aufbewahrung oder Archivierung der Daten erfordern.</p>
                <p>Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen
                        oder deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der Rechte anderer
                        natürlicher oder juristischer Personen, entsprechend archiviert werden.</p>
                <p>Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur Aufbewahrung und Löschung von
                        Daten, die speziell für bestimmte Verarbeitungsprozesse gelten.</p>
                <p>Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die längste
                        Frist maßgeblich.</p>
                <p>Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und beträgt sie mindestens ein Jahr,
                        so startet sie automatisch am Ende des Kalenderjahres, in dem das fristauslösende Ereignis
                        eingetreten ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten gespeichert
                        werden, ist das fristauslösende Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder
                        sonstige Beendigung des Rechtsverhältnisses.</p>
                <p>Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern aufgrund gesetzlicher Vorgaben
                        oder anderer Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen, die ihre
                        Aufbewahrung rechtfertigen.</p>
                <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                <ul className="m-elements">
                        <li><strong>Aufbewahrung und Löschung von Daten: </strong>Die folgenden allgemeinen Fristen
                                gelten für die Aufbewahrung und Archivierung nach deutschem Recht: <ul>
                                        <li>10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen,
                                                Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanz sowie die zu
                                                ihrem Verständnis erforderlichen Arbeitsanweisungen und sonstigen
                                                Organisationsunterlagen, Buchungsbelege und Rechnungen (§ 147 Abs. 3 i.
                                                V. m. Abs. 1 Nr. 1, 4 und 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1
                                                u. 4, Abs. 4 HGB).
                                        </li>
                                        <li>6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder
                                                Geschäftsbriefe, Wiedergaben der abgesandten Handels- oder
                                                Geschäftsbriefe, sonstige Unterlagen, soweit sie für die Besteuerung von
                                                Bedeutung sind, z. B. Stundenlohnzettel, Betriebsabrechnungsbögen,
                                                Kalkulationsunterlagen, Preisauszeichnungen, aber auch
                                                Lohnabrechnungsunterlagen, soweit sie nicht bereits Buchungsbelege sind
                                                und Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3, 5 AO, § 257
                                                Abs. 1 Nr. 2 u. 3, Abs. 4 HGB).
                                        </li>
                                        <li>3 Jahre - Daten, die erforderlich sind, um potenzielle Gewährleistungs- und
                                                Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und Rechte
                                                zu berücksichtigen sowie damit verbundene Anfragen zu bearbeiten,
                                                basierend auf früheren Geschäftserfahrungen und üblichen
                                                Branchenpraktiken, werden für die Dauer der regulären gesetzlichen
                                                Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199 BGB).
                                        </li>
                                </ul></li>
                </ul>
                <h2 id="m10">Rechte der betroffenen Personen</h2><p>Rechte der betroffenen Personen aus der DSGVO: Ihnen
                stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21
                DSGVO ergeben:</p>
                <ul>
                        <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                                Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                                personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                                Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
                                Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
                                Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                                Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
                                einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
                                Verbindung steht.</strong></li>
                        <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
                                Einwilligungen jederzeit zu widerrufen.
                        </li>
                        <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen,
                                ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf
                                weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                        </li>
                        <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben
                                das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der
                                Sie betreffenden unrichtigen Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
                                Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
                                unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
                                eine Einschränkung der Verarbeitung der Daten zu verlangen.
                        </li>
                        <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten,
                                die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem
                                strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren
                                Übermittlung an einen anderen Verantwortlichen zu fordern.
                        </li>
                        <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                                verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei
                                einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                                Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
                                der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
                                gegen die Vorgaben der DSGVO verstößt.
                        </li>
                </ul>

                <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2><p>Wir verarbeiten die Daten der
                Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir
                die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an
                den Browser oder das Endgerät der Nutzer zu übermitteln.</p>
                <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und
                                Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                                Betriebssysteme, Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und
                                Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                                Personen). Protokolldaten (z. B. Logfiles betreffend Logins oder den Abruf von Daten
                                oder Zugriffszeiten.).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                                Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung
                                von Informationssystemen und technischen Geräten (Computer, Server etc.).).
                                Sicherheitsmaßnahmen.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                                "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                lit. f) DSGVO).
                        </li>
                </ul>
                <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                <ul className="m-elements">
                        <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser
                                Onlineangebot wird in Form von sogenannten "Server-Logfiles" protokolliert. Zu den
                                Serverlogfiles können die Adresse und der Name der abgerufenen Webseiten und Dateien,
                                Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf,
                                Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
                                besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören. Die
                                Serverlogfiles können zum einen zu Sicherheitszwecken eingesetzt werden, z. B. um eine
                                Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen
                                Angriffen, sogenannten DDoS-Attacken), und zum anderen, um die Auslastung der Server und
                                ihre Stabilität sicherzustellen; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). </span><strong>Löschung
                                        von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30
                                Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere
                                Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
                                jeweiligen Vorfalls von der Löschung ausgenommen.
                        </li>
                </ul>
                <h2 id="m134">Einsatz von Cookies</h2><p>Cookies sind kleine Textdateien bzw. sonstige Speichervermerke,
                die Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum Beispiel, um den Log-in-Status in
                einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete
                Funktionen eines Onlineangebots zu speichern. Cookies können ferner in Bezug auf unterschiedliche
                Anliegen Einsatz finden, etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und des Komforts von
                Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.</p>
                <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen
                        Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, es sei
                        denn, sie ist laut Gesetzeslage nicht gefordert. Eine Erlaubnis ist insbesondere nicht
                        notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies,
                        unbedingt erforderlich sind, um den Nutzern einen von ihnen ausdrücklich gewünschten
                        Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
                        Einwilligung wird ihnen gegenüber deutlich kommuniziert und enthält die Informationen zur
                        jeweiligen Cookie-Nutzung.</p>
                <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher
                        datenschutzrechtlichen Grundlage wir die personenbezogenen Daten der Nutzer mithilfe von Cookies
                        verarbeiten, hängt davon ab, ob wir sie um eine Einwilligung bitten. Falls die Nutzer
                        akzeptieren, ist die Rechtsgrundlage der Verwertung ihrer Daten die erklärte Einwilligung.
                        Andernfalls werden die mithilfe von Cookies verwerteten Daten auf Grundlage unserer berechtigten
                        Interessen (z. B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebots und der
                        Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im Rahmen der Erfüllung unserer
                        vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unseren
                        vertraglichen Verpflichtungen nachzukommen. Zu welchen Zwecken die Cookies von uns verwertet
                        werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
                        Einwilligungs- und Verarbeitungsprozessen auf.</p>
                <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von
                        Cookies unterschieden:</p>
                <ul>
                        <li><strong>Temporäre Cookies (auch: Session- oder Sitzungscookies):</strong> Temporäre Cookies
                                werden spätestens gelöscht, nachdem ein Nutzer ein Onlineangebot verlassen und sein
                                Endgerät (z. B. Browser oder mobile Applikation) geschlossen hat.
                        </li>
                        <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                                Endgeräts gespeichert. So können beispielsweise der Log-in-Status gespeichert und
                                bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
                                Ebenso können die mithilfe von Cookies erhobenen Nutzerdaten zur Reichweitenmessung
                                Verwendung finden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer
                                von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten sie
                                davon ausgehen, dass diese permanent sind und die Speicherdauer bis zu zwei Jahre
                                betragen kann.
                        </li>
                </ul>
                <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): </strong>Nutzer können die von
                        ihnen abgegebenen Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die
                        Verarbeitung entsprechend den gesetzlichen Vorgaben, auch mittels der Privatsphäre-Einstellungen
                        ihres Browsers, erklären.</p>
                <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                        </li>
                </ul>
                <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                <ul className="m-elements">
                        <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen
                                eine Einwilligungs-Management-Lösung ein, bei der die Einwilligung der Nutzer zur
                                Verwendung von Cookies oder zu den im Rahmen der Einwilligungs-Management-Lösung
                                genannten Verfahren und Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
                                Protokollierung, Verwaltung und dem Widerruf von Einwilligungen, insbesondere bezogen
                                auf den Einsatz von Cookies und vergleichbaren Technologien, die zur Speicherung, zum
                                Auslesen und zur Verarbeitung von Informationen auf den Endgeräten der Nutzer eingesetzt
                                werden. Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die Nutzung
                                von Cookies und die damit verbundenen Verarbeitungen von Informationen, einschließlich
                                der im Einwilligungs-Management-Verfahren genannten spezifischen Verarbeitungen und
                                Anbieter, eingeholt. Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu
                                verwalten und zu widerrufen. Die Einwilligungserklärungen werden gespeichert, um eine
                                erneute Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen
                                Anforderungen führen zu können. Die Speicherung erfolgt serverseitig und/oder in einem
                                Cookie (sogenanntes Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die
                                Einwilligung einem spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern
                                keine spezifischen Angaben zu den Anbietern von Einwilligungs-Management-Diensten
                                vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der Speicherung der
                                Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer Nutzer-Identifikator
                                erstellt, der zusammen mit dem Zeitpunkt der Einwilligung, den Angaben zum Umfang der
                                Einwilligung (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie
                                Informationen über den Browser, das System und das verwendete Endgerät gespeichert
                                wird; <span className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</span>
                        </li>
                </ul>
                <h2 id="m104">Blogs und Publikationsmedien</h2><p>Wir nutzen Blogs oder vergleichbare Mittel der
                Onlinekommunikation und Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser werden für
                die Zwecke des Publikationsmediums nur insoweit verarbeitet, als es für dessen Darstellung und die
                Kommunikation zwischen Autoren und Lesern oder aus Gründen der Sicherheit erforderlich ist. Im Übrigen
                verweisen wir auf die Informationen zur Verarbeitung der Besucher unseres Publikationsmediums im Rahmen
                dieser Datenschutzhinweise.</p>
                <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                                Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                                E-Mail-Adressen oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
                                Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur
                                Autorenschaft oder Zeitpunkt der Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
                                Verweildauer, Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und
                                Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations- und
                                Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
                                Personen).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                        </li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Feedback (z. B. Sammeln von Feedback via
                                Online-Formular). Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                                "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                lit. f) DSGVO).
                        </li>
                </ul>
                <h2 id="m182">Kontakt- und Anfrageverwaltung</h2><p>Bei der Kontaktaufnahme mit uns (z. B. per Post,
                Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
                Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur
                Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
                <ul className="m-elements">
                        <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name,
                                Wohnadresse, Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                                E-Mail-Adressen oder Telefonnummern). Inhaltsdaten (z. B. textliche oder bildliche
                                Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z. B. Angaben zur
                                Autorenschaft oder Zeitpunkt der Erstellung).
                        </li>
                        <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                        <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation; Organisations- und
                                Verwaltungsverfahren; Feedback (z. B. Sammeln von Feedback via Online-Formular).
                                Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
                        </li>
                        <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt
                                "Allgemeine Informationen zur Datenspeicherung und Löschung".
                        </li>
                        <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                lit. f) DSGVO).
                        </li>
                </ul>
                <p className="seal"><a href="https://datenschutz-generator.de/"
                                       title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                       target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
                        Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>

        </div>
    )
}