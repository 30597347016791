// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
    margin: auto;
    font-size: 30px;
}

h1 {
    margin: auto;
    font-size: 40px;
}

.resizingImage:hover {
    scale: 1.1;
    align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/res/projectsStyle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,qBAAqB;AACzB","sourcesContent":["h2 {\n    margin: auto;\n    font-size: 30px;\n}\n\nh1 {\n    margin: auto;\n    font-size: 40px;\n}\n\n.resizingImage:hover {\n    scale: 1.1;\n    align-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
