export function BlogHome() {

    return (
        <div className="">
            <BlogCategoryFilters/>
            <div className="blog flex place-content-center">
                <BlogPosts/>
            </div>
        </div>
    )
}

function BlogPosts() {
    return (
        <div className="blogPost rounded-3xl text-black bg-stone-50 w-1/3 h-16 place-content-center m-4">
            <p>Tbd.</p>
        </div>
    )
}

function BlogCategoryFilters() {
    return (
        <div className="absolute place-content-start m-16">
            <p>Das sind die Filter</p>
        </div>
    )
}