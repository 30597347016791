export function LoggiFinProject(){
    return (
        <div>

            <h1 className="text-3xl font-serif">LoggiFin - Finanzlogging nach meinen Vorstellungen</h1>


            <h2 class="text-xl"> Kurzbeschreibung</h2>
            <p>LoggiFin ist ein Projekt, welches ich begonnen habe, um C# und .net zu lernen. LoggiFin verwendet Blazor für die Darstellung der Weboberfläche.
                Im Backend steht eine MySQL Datenbank, welche alle relevaten Daten bereitstellt. Im Verlauf der Projekts sind verschiedene Ansichten entstanden um den Stand von Finanzen visualisieren zu können.
                Darunter z.B. eine Vermögensaufteilung und einen Gesamtvermögensgraphen. Um Daten an die Datenbank übermitteln zu können, stellt LoggiFin eine weitere Seite zur Verfügung. Festkosten werden ebenfalls in LoggiFin getracked
                und es ist möglich diese Festkosten aufzuteilen. Auch diese Daten können dann in LoggiFin visualisiert werden.
            </p>

        </div>
    )
}