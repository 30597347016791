import React, {Component} from "react";

export class ImageWithPlaceholder extends Component {

    styleClassesImage = "";
    styleClassesContainer = "";
    fileName = "";
    folderUrl = "";
    hasImagePlaceholder = false;

    constructor(props) {
        super();
        this.state = {loading: true};
        this.styleClassesImage = props.styleClassesImage;
        this.styleClassesContainer = props.styleClassesContainer;
        this.fileName = props.fileName;
        this.folderUrl = props.folderUrl;
        this.hasImagePlaceholder = props.hasImagePlaceholder;
    }

    render() {
        //check if image exists
        try {
            require(`../${this.folderUrl}${this.fileName}`);
        }catch (e){
            return;
        }
        //check if image placeholder -> if yes load the image
        if(this.hasImagePlaceholder){
            return (
                <div className={this.styleClassesContainer}>
                    <img className={this.styleClassesImage} src={require(`../${this.folderUrl}placeholder-${this.fileName}`)} alt="couldnt be loaded" hidden={!this.state.loading} />
                    <img className={this.styleClassesImage} src={require(`../${this.folderUrl}${this.fileName}`)} alt="couldnt be loaded"
                         hidden={this.state.loading} onLoad={() => { if(this.state.loading) {this.setState({loading: false})}}} />
                </div>
            )
        }

        //if placeholder is not an image show a loading symbol
        return (
            <div className={this.styleClassesContainer}>
                <div className='flex bg-stdBg rounded-2xl justify-center'><img className={'animate-spin p-5'} src={require('../res/hourglass.png')} alt="couldnt be loaded"
                          hidden={!this.state.loading}/></div>
                <img className={this.styleClassesImage} src={require(`../${this.folderUrl}${this.fileName}`)}
                     alt="couldnt be loaded"
                     hidden={this.state.loading} onLoad={() => { if(this.state.loading) {this.setState({loading: false})}}} />
            </div>
        )

    }
}