import * as React from 'react';
import { TextField, Box, Button } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {apiUrl} from "../util/GlobalProperties";

async function sendContactRequest(from, message){

    const resp = await fetch(apiUrl + "contactRequest", {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
        "sender":from,
        "message":message
    })
    })

    return resp.status
}

export function ContactPage(){

    let from = ""
    let message = ""

    const [open, setOpen] = React.useState(false);
    const [openSucc, setOpenSucc] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenSucc = () => {
        setOpenSucc(true);
    };

    const handleCloseSucc = () => {
        setOpenSucc(false);
    };

    return (

        <div className='flex place-content-center'>

        <div className="py-5 my-5 flex place-content-center bg-slate-50 w-1/2 rounded-3xl">


            <Box>
                <div className='py-5'>
                    <TextField variant='outlined' fullWidth={true} color='secondary' label="email" onBlur={(event) => {
                        from = event.target.value
                    }} sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },
                    }} />
                </div>
                <div>
                    <TextField variant='outlined' multiline rows={7} fullWidth={true} label="message" onBlur={(event) => {
                        message = event.target.value
                    }} sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },
                    }} />
                </div>
                <div className='py-5 flex place-content-center'>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={ async () => {
                        let res = await sendContactRequest(from, message)
                        console.log(res)
                        if(res === 200){
                            handleClickOpenSucc()
                        }else{
                            handleClickOpen()
                        }
                    }}> Send </Button>
                </div>
            </Box>
            

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {"Couldn't be sent"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Message couldn't be sent. Please enter a valid name and a message!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSucc}
                onClose={handleCloseSucc}
                aria-labelledby="alert-dialog-titlesucc"
                aria-describedby="alert-dialog-descriptionsucc"
            >
            <DialogTitle id="alert-dialog-titlesucc">
                {"Sent"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-descriptionsucc">
                    Message was sent! Thank you!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSucc}>Close</Button>
                </DialogActions>
            </Dialog>

        </div>
        
        </div>
        
    )
}