import './App.css';
import { MenuBar } from './LayoutComponents/MenuBar';
import { Footer } from './LayoutComponents/Footer';
import { ProjectsPage } from './Sites/Projects';
import { DisplayHome } from './Sites/Home'
import { AboutPage } from './Sites/About';
import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Login from "./Sites/Login";
import {BlogHome} from "./Sites/Blog";
import {DisplayPolicy} from "./Sites/PrivacyPolicy";

function App() {
  const [token, setToken] = useState();

  return (
    <div className='text-white w-full h-full relative'>
      <Router>

        <Switch>
          <Route path="/about">
            <title>About</title>
            <div className="h-screen">
            <MenuBar />
            { !token ? <Login setToken={setToken} /> : <AboutPage /> }

            </div>

          </Route>
          <Route path="/projects">
            <div>
              <MenuBar />
              <title>Projects</title>
              <ProjectsPage />
            </div>

          </Route>
          <Route path="/blog">
            <title> Blog </title>
            <MenuBar />
            <BlogHome />
          </Route>
          <Router path="/privacy">
            <title> Privacy Policy</title>
            <MenuBar />
            <DisplayPolicy />
          </Router>
          <Route path="/">
            <title>Mphion Home</title>
            <div>
              <MenuBar />
              <DisplayHome />
            </div>
          </Route>
        </Switch>

        <Footer />

      </Router>

    </div>
  );
}

export default App;
